import * as React from "react"
import Helmet from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"

const TermsAndConditionsPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms &amp; Conditions - Hyperfluid Solutions</title>
      </Helmet>
      <div className="relative bg-white overflow-hidden">
        <div className="relative pt-6 pb-16 sm:pb-8">
          <Header></Header>
        </div>
        <div className="hidden lg:block lg:absolute lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="font-serif relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Terms &amp; Conditions
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
                            Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
                            aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
                            egestas fringilla sapien.
                        </p> */}
          </div>
          <div className="mt-6 prose prose-purple prose-lg text-gray-500 mx-auto">
            <ol>
              <li>
                Hyperfluid Solutions Corp. starts working on a project upon
                receipt of a down payment. Unless otherwise specified in
                writing, the client is charged the remaining balance upon
                completion of the project. Websites are made live upon
                completion of the project, when all payments are cleared. If
                applicable, hosting and domain name registration fees must be
                paid every month.
              </li>
              <li>
                Additional services come at additional fees, on a fee for
                service basis or on an hourly rate. If clients require
                additional services, the correspondent fee is added to their
                invoice and they are charged accordingly.
              </li>
              <li>
                Delays in project completion may occur when:
                <ul>
                  <li>There is a delay in any payments</li>
                  <li>
                    The client requests further changes in the design,
                    structure, or content of the project
                  </li>
                  <li>
                    The client purchases an additional service related to the
                    project
                  </li>
                  <li>
                    There is a delay in the receipt of any materials form the
                    client
                  </li>
                  <li>
                    Time frame estimates are approximate only and are subject to
                    change at any time at Hyperfluid Solutions Corp.'s
                    discretion. At any time, and at its whole discretion,
                    Hyperfluid Solutions Corp.'s may decide to put a project on
                    hold.
                  </li>
                </ul>
              </li>
              <li>
                All of our services are 100% satisfaction guaranteed. We don't
                stop working until our clients are completely satisfied with
                their solution. That is our commitment. However, Hyperfluid
                Solutions Corp. will under no circumstances be held responsible
                to satisfy unreasonable expectations or request Hyperfluid
                Solution Corp.'s discretion to decide whether or not a request
                or an expectation is reasonable or lies within the scope of
                offer of services to its client. As an example, if the client
                purchases a certain service, but in order to be fully satisfied,
                Hyperfluid Solutions Corp. judges that an additional service is
                necessary, then it is no longer Hyperfluid Solutions Corp.'s
                responsibility or commitment to satisfy the client. In other
                words, Hyperfluid Solutions Corp. guarantees 100% satisfaction
                within the framework of its original agreement with the client,
                according to Hyperfluid Solutions Corp.'s understanding.
              </li>
              <li>
                Services or part of services (i.e. elements of a project) that
                have been approved by the client are considered as services with
                which the client is 100% satisfied. Any further work on these
                services (elements of the project) will be invoiced accordingly.
              </li>
              <li>
                Unless clearly specified otherwise, all project statuses are set
                as “completed” after a 3 month period has passed (calculated
                from the first payment date).This means no more work will be
                done on that project and no service will be provided. This
                doesn't apply to hosting and domain name registration services,
                which are provided independently of the project status, and only
                according to the client's payments.
              </li>
              <li>
                If client is not satisfied with Hyperfluid Solutions Corp.'s
                Services, client can ask for a refund on any unused monies.
                Hyperfluid Solutions Corp. will be happy to exercise a refund,
                after doing it on review.
              </li>
            </ol>

            <h2>About credit cards data processing</h2>
            <p>
              Please keep in mind that for security reasons, Hyperfluid
              Solutions Corp. always:
            </p>
            <ul>
              <li>
                Prevents unauthorized access, maintains data accuracy, and
                ensures the correct use of information, through appropriate
                physical, electronic, and managerial procedures to safeguard and
                secure the information it collects online and offline.
              </li>
              <li>
                Hires the services of reliable third party companies to manage
                credit card information and payment gateway secured encryptions
                for the websites of its clients. Such companies charge fees per
                transaction and/or fees per month for which the client is
                responsible.
              </li>
              <li>
                Most such companies offer various fee plans to best suit the
                needs of the client's business depending on the volume of the
                client's online transactions.
              </li>
            </ul>

            <h2>
              About Hyperfluid Solutions Corp.'s web design projects and offices
            </h2>
            <p>
              Hyperfluid Solutions Corp. doesn't assume any responsibility or
              liability for the content of the websites it designs and/or hosts.
              Hyperfluid Solutions Corp. has no control over any information,
              materials, or products found thereon.
            </p>
            <p>
              Because of the nature of web design and programming work, websites
              always appear differently on different computers. Screen
              resolution, operating system, and browsing software are just a few
              examples of variables that may affect the way a website looks. The
              client must be aware of these possible variations and Hyperfluid
              Solutions Corp. will not be held responsible for them.
            </p>
            <p>
              Should you have any questions or concerns about these terms and
              conditions, please send us an email at{" "}
              <a
                href="mailto:hello@hyperfluidsolutions.com"
                target="_blank"
                rel="noreferrer"
              >
                hello@hyperfluidsolutions.com
              </a>
              . Thank you for doing business with us! We really appreciate it.
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default TermsAndConditionsPage;
